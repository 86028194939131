import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { useTranslation } from "gatsby-plugin-react-i18next"
import gubretas1 from "../../assets/img/gubretas1.png"
import gubretas2 from "../../assets/img/gubretas2.png"
import gubretas3 from "../../assets/img/gubretas4.png"
import gubretas4 from "../../assets/img/gubretas3.png"

export default function GubretasProjesi() {
  const { t } = useTranslation()
  return (
    <Layout>
      <SEO title="Gübretaş Projesi" />
      <div className="projeler-container page">
        <div className="ic-proje">
          <img src={gubretas1} alt="" className="proje-icGorsel" />
          <div className="proje-kunye">
            <span>{t("project-brand")}</span>: {t("gubretas")}
            <br />
            <span>{t("project-sector")}</span>: {t("kamu")}
            <br />
            <span>{t("project-subject")}</span>: {t("vip-2012")}
            <br />
            <span>{t("project-artwork")}</span>: {t("oniki-modern-hat")}
          </div>
          <img src={gubretas2} alt="" className="proje-icGorsel" />
        </div>
        <div className="seperator"></div>
        <div className="ic-proje">
          <img src={gubretas3} alt="" className="proje-icGorsel" />
          <div className="proje-kunye">
            <span>{t("project-brand")}</span>: {t("gubretas")}
            <br />
            <span>{t("project-sector")}</span>: {t("kamu")}
            <br />
            <span>{t("project-subject")}</span>: {t("vip-hediyelik")}
            <br />
            <span>{t("project-artwork")}</span>: {t("allah-cc-tekrari")}
          </div>
          <img src={gubretas4} alt="" className="proje-icGorsel" />
        </div>
      </div>
    </Layout>
  )
}
